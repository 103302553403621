.mainContainer {
  position: relative;
  width: 600px;
  height: 300px;
  margin-top: 30px;
  /* background-color: #6f6fe250; */
}
.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
}
.frame {
  position: absolute;
  text-align: center;
  padding: 5px;
  color: #323434;
  /* border: solid #323434 1px; */
  border-radius: 3px;
  z-index: 1;
  font-family: 'Roboto', 'sans-serif';
}
.line {
  position: absolute;
  background-color: #d7d7d7;
  background-color: #dce2e6;
  background-color: #e0e8fa;


  width: 2px;
}
.highlight {
  background-color: #e0e0e0;
  background-color: #eceff1;
  background-color: #e9eefb;
  /* border: solid #4285f4 1px; */
  /* color: white; */
}
.frame16 {
  top: 15%;
  left:0%;
  width: 70px;
}
.line16 {
  top: 29.5%;
  left: 6.5%;
  height: 11.5%;
}
.frame15 {
  bottom: 0%;
  left:0%;
  width: 128px;
}
.line15 {
  bottom: 13.5%;
  left:6.5%;
  height: 12%;
}
.frame8 {
  top: 0;
  left: 0%;
}
.line8 {
  top: 8%;
  left: 15%;
  height: 29.5%;
}
.frame5 {
  bottom: 5%;
  left: 32%;
}
.line5 {
  bottom: 12.5%;
  left: 37%;
  height:6%;
}
.frame1 {
  top: 15%;
  left: 36%;
  width: 125px;
}
.line1 {
  top: 29.5%;
  left: 47%;
  height: 7%;
}
.frame2 {
  bottom: 20%;
  left: 54%;
}
.line2 {
  bottom: 27.3%;
  left: 59%;
  height: 15%;
}
.frame7 {
  top: 3%;
  left: 55%;
}
.line7 {
  top: 11%;
  left: 67.5%;
  height: 13%;
}
.frame3 {
  bottom: 5%;
  left: 71%;
  width: 110px;
}
.line3 {
  bottom: 19%;
  left: 81%;
  height: 13%;
}
.frame4 {
  top: 3%;
  left: 83%;
}
.line4 {
  top: 11%;
  left: 88.8%;
  height: 9%;
}